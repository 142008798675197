import React from 'react'
import {Redirect, Route, BrowserRouter as Router, Switch} from 'react-router-dom'
import {MuiThemeProvider} from "@material-ui/core"
import theme from "./theme/Theme"
import Home from "./Home"

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <div>
        <Router>
          <Switch>
            <Route path="/" component={Home}/>
            <Redirect to="/"/>
          </Switch>
        </Router>
      </div>
    </MuiThemeProvider>
  )
}

export default App