import {CssBaseline, Grid, Typography} from '@material-ui/core'
import React, {FunctionComponent} from 'react'
import {makeStyles, Theme} from '@material-ui/core/styles'
import theme from './theme/Theme'
import logo from './theme/common/assembled-universe-logo.svg'

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(12),
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(20)
    }
  },
  logo: {
    [theme.breakpoints.down('sm')]: {
      width: '270px'
    }
  },
  text: {
    paddingTop: '54px',
    textAlign: 'center',
    width: '770px',
    [theme.breakpoints.down('sm')]: {
      width: '270px',
      paddingTop: '36px'
    }
  }
}))

const Home: FunctionComponent = () => {
  const classes = useStyles(theme)


  return (
    <React.Fragment>
      <CssBaseline/>
      <Grid container direction="column" alignItems="center" className={classes.root}>
        <Grid item >
          <img src={logo} alt="Assembled Universe" className={classes.logo}/>
        </Grid>
        <Grid item className={classes.text}>
          <Typography variant="body1">
            Assembled Universe systematically develops the next generation of consumer, software, and financial services
            brands and platforms into an ever expanding universe of companies.
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}


export default Home

